import ExclamationMarkIcon from "../../assets/icons/ExclamationMarkIcon";
import WaterDropIcon from "../../assets/icons/WaterDropIcon";
import useWindowSize from "../../hooks/useWindowSize";
import MenuTile from "../Shared/MenuTile";

function ReportsMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 mt-7 text-center sm:text-left !text-triple-blue">
          Reports
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
          Reports
        </h1>
      )}
      <div className="mt-4 flex md:flex-row flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Consumption Report"
          icon={
            <WaterDropIcon
              className="w-20 h-20 align-sub"
              strokeColor="#757782"
            />
          }
          navigateTo="/reports/water-consumption"
        />
        <MenuTile
          title="Status Report"
          icon={<ExclamationMarkIcon />}
          navigateTo="/reports/status"
        />
      </div>
    </>
  );
}

export default ReportsMenuPage;
