import { Table } from "antd";
import PropTypes from "prop-types";
import "./Table.scss";

const propTypes = {
  data: PropTypes.array,
};

const defaultProps = {
  data: [],
};

function CustomTable({ data }) {
  if (data && data.length > 0) {
    // Table column headers
    const headerKeys = data && data.length > 0 ? Object.keys(data[0]) : [];
    const headers = headerKeys.map((h) => ({
      title: h,
      dataIndex: h,
      key: h,
      width: 200,
    }));

    // Actual table data
    const mappedDataToHeader = data.map((row) =>
      headerKeys.map((header) => {
        return {
          [header]: row[header],
        };
      }),
    );
    const refinedData = mappedDataToHeader.map((result, key) => {
      return Object.assign({}, ...result, {
        key,
      });
    });

    return (
      <Table
        columns={headers}
        dataSource={refinedData}
        pagination={false}
        className="reports-table"
      />
    );
  }

  return (
    <Table
      columns={[]}
      dataSource={[]}
      scroll={{
        x: 500,
      }}
      className="reports-table"
    />
  );
}

CustomTable.propTypes = propTypes;
CustomTable.defaultProps = defaultProps;

export default CustomTable;
