import { useState, useEffect, useRef } from "react";

import { Form } from "antd";

import useWaterConsumptionReport from "../../api/hooks/useWaterConsumptionReport";
import WaterDropIcon from "../../assets/icons/WaterDropIcon";
import ReportsLayout from "./Layout/ReportsLayout";

function WaterConsumptionReport() {
  const [showData, setShowData] = useState(false);
  const [generateReportForm] = Form.useForm();
  const initialFormValues = useRef({});
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [aggregationLevel, setAggregationLevel] = useState(null);
  const [bucketType, setBucketType] = useState(null);
  const [includePreviousYear, setIncludePreviousYear] = useState(false);
  const [propertyNameField, setPropertyNameField] = useState(null);
  const [propertyAddressField, setPropertyAddressField] = useState(null);
  const [floorNameField, setFloorNameField] = useState(null);
  const [systemNameField, setSystemNameField] = useState(null);
  const [deviceNameField, setDeviceNameField] = useState(null);
  const [deviceSerialNumberField, setDeviceSerialNumberField] = useState(null);
  const [deviceLocationField, setDeviceLocationField] = useState(null);
  const [deviceTypeField, setDeviceTypeField] = useState(null);

  const isValidDate = (d) => {
    if (d === null) {
      return false;
    }

    return d instanceof Date || !Number.isNaN(d);
  };

  useEffect(() => {
    if (isValidDate(reportDate)) {
      setShowData(true);
      generateReportForm.submit();
    }
  }, [
    generateReportForm,
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
  ]);

  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setReportDate(reportDate);
    setDateFormat(dateFormat);
    setAggregationLevel(aggregationLevel);
    setBucketType(bucketType);
    setIncludePreviousYear(includePreviousYear);
    setPropertyNameField(propertyNameField);
    setPropertyAddressField(propertyAddressField);
    setFloorNameField(floorNameField);
    setSystemNameField(systemNameField);
    setDeviceNameField(deviceNameField);
    setDeviceSerialNumberField(deviceSerialNumberField);
    setDeviceLocationField(deviceLocationField);
    setDeviceTypeField(deviceTypeField);
  };

  initialFormValues.current = {
    date: null,
    dateFormat: "yyyy MMM",
    aggregationLevel: "Device",
    bucketType: "year",
    includePreviousYear: false,
    unitOfMeasurement: "liters",
  };

  const handleFieldsChange = (changedFields) => {
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;
    if (fieldName === "date") {
      if (fieldValue === null || fieldValue === "") {
        generateReportForm.setFields([
          {
            name: "date",
            errors: ["Date field is required."],
          },
        ]);
      }
    }
  };

  const { refetch: getWaterConsumptionReport } = useWaterConsumptionReport(
    {
      filtersWithDeviceId: {
        deviceId: null,
        propertyId: propertyId === "" ? null : propertyId,
        floorId: floorId === "" ? null : floorId,
        systemId: systemId === "" ? null : systemId,
      },
      aggregationLevel,
      bucketType,
      date: new Date(reportDate),
      dateFormat,
      upToDate: false,
      includeBuckets: false,
      includeTotal: true,
      includePreviousYear,
      reportFileType: "CSV",
      fields: {
        PropertyName: propertyNameField,
        PropertyAddress: propertyAddressField,
        FloorName: floorNameField,
        SystemName: systemNameField,
        DeviceName: deviceNameField,
        DeviceSerialNumber: deviceSerialNumberField,
        DeviceLocation: deviceLocationField,
        DeviceType: deviceTypeField,
      },
    },
    { enabled: false },
  );

  return (
    <ReportsLayout
      iconTitle="Consumption"
      icon={
        <WaterDropIcon className="w-6 h-6 align-sub" strokeColor="#757782" />
      }
      fetchData={getWaterConsumptionReport}
      useGraph
      handleFieldsChange={handleFieldsChange}
      initialFormValues={initialFormValues}
      handleOnChangeFilterCallback={handleOnChangeFilterCallback}
      generateReportForm={generateReportForm}
      reportDate={reportDate}
      setReportDate={setReportDate}
      propertyId={propertyId}
      floorId={floorId}
      systemId={systemId}
      includePreviousYear={includePreviousYear}
      showData={showData}
    />
  );
}

export default WaterConsumptionReport;
